import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { mutateToPath } from "src/utilities/mutateFn";

interface IStaffLoginBody {
  idToken: string;
}
interface IStaffLoginResponse {}

export function useStaffLogin(): UseMutationResult<
  IStaffLoginResponse,
  unknown,
  IStaffLoginBody
> {
  return useMutation(
    mutateToPath<IStaffLoginResponse, IStaffLoginBody>(
      "/auth/user-session/staffLogin"
    )
  );
}
