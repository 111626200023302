import { EMerchantHierarchyType } from "@till/till-auth.fed-types";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { mutateToPath } from "src/utilities/mutateFn";

interface ILoginBody {
  email: string;
  password: string;
}

interface ILoginResponse {
  merchantHierarchyId?: string;
  merchantHierarchyType?: EMerchantHierarchyType;
}

export function useLogin(): UseMutationResult<
  ILoginResponse,
  unknown,
  ILoginBody
> {
  return useMutation(
    mutateToPath<ILoginResponse, ILoginBody>("/auth/user-session/login")
  );
}
