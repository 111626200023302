// https://github.com/facebook/react/issues/14981#issuecomment-468460187
import { useState, useCallback } from "react";

export const useAsyncError = (): ((error: unknown) => void) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setError] = useState();
  return useCallback(
    (e) => {
      setError(() => {
        throw e;
      });
    },
    [setError]
  );
};
