import { ParsedUrlQuery } from "querystring";

interface IBuildPathFromQueryResopnse {
  path: string;
  href: string | null;
}

export function buildPathFromQuery(
  query: Record<string, string> | ParsedUrlQuery
): IBuildPathFromQueryResopnse | null {
  if (!query) {
    return null;
  }

  const ignoredKeys = ["href", "redirectReasons"];
  const search = new URLSearchParams();

  let href = null;

  Object.entries(query).forEach(([key, value]) => {
    if (value == null || ignoredKeys.includes(key)) {
      if (key === "href") {
        href = query[key];
      }
      return;
    }
    if (Array.isArray(value)) {
      value.forEach((v) => {
        search.append(key, v);
      });
      return;
    }
    search.append(key, value);
  });

  const path = search.toString();

  return {
    path,
    href,
  };
}
