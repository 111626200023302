import * as React from "react";
import {
  Alert as MuiAlert,
  AlertTitle,
  Typography,
  Button,
  AlertProps,
} from "@mui/material";

import { EIcon, Icon } from "../Icon";

export enum EAlertSeverity {
  ERROR = "error",
  WARNING = "warning",
  SUCCESS = "success",
  INFORMATIVE = "info",
  DEFAULT = "default",
}

interface TillAlertStyle {
  main?: React.CSSProperties;
  cta?: React.CSSProperties;
  icon?: React.CSSProperties;
}

export interface IAlert extends Omit<AlertProps, "severity"> {
  title: string;
  description?: string;
  severity?: EAlertSeverity;
  withIcon?: boolean;
  withClose?: boolean;
  onClose?: () => void;
  cta?: {
    label: string;
    onClick: () => void;
  };
  tillStyle?: TillAlertStyle;
}

export const iconMapping = {
  [EAlertSeverity.ERROR]: <Icon icon={EIcon.ERROR_STATUS_ICON} />,
  [EAlertSeverity.SUCCESS]: <Icon icon={EIcon.SUCCESS_STATUS_ICON} />,
  [EAlertSeverity.INFORMATIVE]: <Icon icon={EIcon.INFO} />,
  [EAlertSeverity.WARNING]: <Icon icon={EIcon.WARNING_STATUS} />,
};

const DefaultIcon = () => <Icon icon={EIcon.TOOLTIP} />;

export default function Alert(props: IAlert): JSX.Element {
  const {
    title,
    description,
    severity = EAlertSeverity.DEFAULT,
    withIcon = true,
    onClose,
    withClose = true,
    cta,
    tillStyle,
  } = props;

  const isDefault = severity === EAlertSeverity.DEFAULT;
  const icon = isDefault ? DefaultIcon() : undefined;

  return (
    <MuiAlert
      severity={isDefault ? undefined : severity}
      onClose={withClose ? onClose : undefined}
      iconMapping={iconMapping}
      icon={withIcon ? icon : false}
      color={severity}
      className={cta ? "MuiAlert-withCta" : undefined}
      sx={{ borderRadius: 1 }}
      style={tillStyle?.main}
      action={
        cta && (
          <Button
            variant="text"
            sx={(theme) => ({
              fontSize: theme.typography.fontSize,
              color: tillStyle?.cta?.color
                ? tillStyle?.cta?.color
                : theme.palette.text.primary,
              fontWeight: 600,
            })}
            onClick={cta.onClick}
          >
            {cta.label}
          </Button>
        )
      }
    >
      <AlertTitle
        sx={(theme) => ({
          color: theme.palette.text.primary,
          m: 0,
          mr: { md: 0.5 },
        })}
      >
        <Typography fontWeight="500" variant="body2">
          {title}
        </Typography>
      </AlertTitle>
      {description && (
        <Typography
          variant="subtitle2"
          sx={(theme) => ({
            color: theme.palette.text.primary,
            fontSize: { md: theme.typography.fontSize },
          })}
        >
          {description}
        </Typography>
      )}
    </MuiAlert>
  );
}
