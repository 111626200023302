import { useRouter } from "next/router";

export function useSafePush(): (
  preferred: string,
  fallback: string
) => Promise<boolean> {
  const router = useRouter();

  return function safePush(preferred: string, fallback: string) {
    let routeUrl = fallback;

    const url = new URL(preferred, window.location.origin);
    if (url.origin === window.location.origin) {
      routeUrl = preferred;
    }

    return router.push(routeUrl);
  };
}
