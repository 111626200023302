import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";

import { ActiveSession } from "src/components/ColoredIcons/ActiveSession";
import { Dialog } from "src/components/Dialog";

export function SuspendedDialog(): JSX.Element {
  const router = useRouter();
  const t = useTranslations("EXISTING_USER.suspendedAccount");
  const isOpen = router.query.reason === "access";

  function handleClose() {
    router.replace(router.pathname, undefined, { shallow: true });
  }

  return (
    <Dialog isOpen={isOpen} disableMobileFullscreen>
      <Stack alignItems="center" spacing={3}>
        <ActiveSession />

        <Stack spacing={1}>
          <Typography component="h2" variant="h1" align="center">
            {t("title")}
          </Typography>
          <Typography variant="body2" color="text.hint" align="center">
            {t("subTitle")}
          </Typography>
        </Stack>

        <Button onClick={handleClose}>{t("buttonLabel")}</Button>
      </Stack>
    </Dialog>
  );
}
