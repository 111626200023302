import { Grid, Typography, Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useTranslations } from "next-intl";

import { mutateToPath } from "src/utilities/mutateFn";
import { Dialog } from "src/components/Dialog";
import { NextLinkComposed } from "src/components/Link";

export interface IBreachedCorpusesPasswordDialog {
  isOpen: boolean;
  message: string;
  email: string;
}

interface IFormFields {
  email: string;
}

export function BreachedCorpusesPasswordDialog({
  isOpen,
  message,
  email,
}: IBreachedCorpusesPasswordDialog): JSX.Element {
  const t = useTranslations("EXISTING_USER.breachedCorpusesPassword");
  const { mutateAsync } = useMutation<unknown, unknown, IFormFields>(
    mutateToPath("/vision/users/forgottenPassword")
  );

  const handleSendOtp = async () => {
    await mutateAsync({ email: email as string });
  };

  const params = new URLSearchParams();
  params.append("email", email);

  const hrefLink = `/forgot-password/success?${params.toString()}`;

  return (
    <Dialog isOpen={isOpen}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12} lg={9}>
          <Typography component="p" variant="h2" align="center">
            {message}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            to={hrefLink}
            component={NextLinkComposed}
            onClick={() => handleSendOtp()}
          >
            {t("buttonLabel")}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}
