import { Stack } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { GetServerSidePropsContext, GetStaticPropsResult } from "next";

import { useSafePush } from "src/hooks/auth/useSafePush";
import { HttpError } from "src/utilities/HttpError";
import { BreachedCorpusesPasswordDialog } from "src/pageComponents/AuthPages/BreachedCorpusPasswordDialog.tsx";
import { Form, IFormProps } from "src/components/Form";
import { EInputType } from "src/components/Form/inputTypes";
import { ConfirmEmailModal } from "src/pageComponents/AuthPages/ConfirmEmail";
import { SuspendedDialog } from "src/pageComponents/AuthPages/SuspendedDialog";
import { StaffLoginButton } from "src/pageComponents/AuthPages/StaffLoginButton";
import { useValidateEmail } from "src/components/Form/validation/useValidateEmail";
import { useValidationBuilder } from "src/components/Form/validation/useValidationBuilder";
import { useLogin } from "src/hooks/auth/useLogin";
import { sendAmplitudeData } from "src/utilities/amplitude";
import {
  CommonStaticProps,
  getStaticProps,
} from "src/utilities/getStaticProps";
import { PublicLayout } from "src/components/Layout/Page/Public";
import AuthDisclaimer from "src/pageComponents/AuthPages/Disclaimer";
import SignInForm from "src/pageComponents/FormsShared/Signin";
import { buildPathFromQuery } from "src/utilities/buildPathFromQuery";
import { useTillTranslations } from "src/hooks/i18n/useTillTranslations";

interface IFormFields {
  email: string;
  password: string;
}

interface ISignInProps {
  signInProps: {
    destination?: string;
    userEmail?: string;
    sessionId?: string;
  };
}

export default function SignInPage(props: ISignInProps): JSX.Element {
  const router = useRouter();
  const { t } = useTillTranslations("EXISTING_USER");
  const safePush = useSafePush();

  const { signInProps } = props;

  // redirect if logged in
  useEffect(() => {
    if (
      props &&
      props.signInProps &&
      props.signInProps.sessionId &&
      props.signInProps.destination
    ) {
      const pushTo = `${
        signInProps.destination ?? router.query.href ?? "/dashboard"
      }`;
      safePush(pushTo, `/dashboard`);
    }
  }, [props]);

  const { mutateAsync: signInMutate } = useLogin();
  const initialValues: IFormProps<IFormFields>["initialValues"] = {
    email: signInProps.userEmail ?? "",
    password: "",
  };

  const validate: IFormProps<IFormFields>["validate"] = useValidationBuilder({
    email: {
      type: EInputType.TEXT,
      required: true,
      validationTests: [useValidateEmail()],
    },
    password: {
      type: EInputType.TEXT,
      required: true,
    },
  });

  const [isConfirmEmailOpen, setIsConfirmEmailOpen] = useState<boolean>(false);
  const [isBreachedPasswordModalOpen, setIsBreachedPasswordModal] =
    useState<boolean>(false);

  const [email, setEmail] = useState<string>("");

  async function handleSubmit(values: IFormFields) {
    sendAmplitudeData("Sign-in/Click/signin");

    try {
      await signInMutate(values);
    } catch (error) {
      switch ((error as HttpError).code) {
        case "UserNotConfirmedException":
          setIsConfirmEmailOpen(true);
          setEmail(values.email);
          return;
        case "PasswordResetRequiredException":
          setIsBreachedPasswordModal(true);
          return;
        case "InvalidUserRoleException":
          throw new Error(t("error.accountSuspended"));
        case "NotAuthorizedException":
          throw new Error(t("error.incorrectCredentials"));
        case "InvalidStatus":
          throw new Error(t("error.accountLockedOut"));
        default:
          throw error;
      }
    }
    const redirectPath = `${
      signInProps.destination ?? router.query.href ?? "/dashboard"
    }`;

    await safePush(redirectPath, `/dashboard`);
  }

  return (
    <>
      <Stack spacing={4} justifyContent="center">
        <Form<IFormFields>
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleSubmit}
          submitLabel={t("cta.signIn")}
          fullWidth
        >
          <SignInForm translation="EXISTING_USER" email={initialValues.email} />
        </Form>
        {/* <Typography>
          {t("cta.register.text")}{" "}
          <Link
            href="/register"
            onClick={() => {
              sendAmplitudeData("Sign-in/Click/register");
            }}
          >
            {t("cta.register.link")}
          </Link>
        </Typography> */}
        <StaffLoginButton />
        <AuthDisclaimer translation="EXISTING_USER" />
      </Stack>
      <SuspendedDialog />
      <ConfirmEmailModal isOpen={isConfirmEmailOpen} email={email} />
      <BreachedCorpusesPasswordDialog
        isOpen={isBreachedPasswordModalOpen}
        message={t("breachedCorpusesPassword.text")}
        email={email}
      />
    </>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const pageComponentKeys = [
    "EXISTING_USER",
    "INPUTS",
    "FORM",
    "CONFIRM_EMAIL",
    "ACTIVE_SESSION_VIEW",
    "NOT_FOUND_VIEW",
  ];
  const staticProps: GetStaticPropsResult<CommonStaticProps> &
    Partial<{ props: CommonStaticProps }> = await getStaticProps(
    context,
    pageComponentKeys
  );

  const { code, email, href } = context.query;

  const { sessionId } = context.req.cookies;

  let destination = null;

  const userEmail = email ?? null;

  if (code && email && href) {
    const resourcePath = buildPathFromQuery(context.query);

    if (resourcePath) {
      const { path, href: resourcePathHref } = resourcePath;

      destination = resourcePathHref ? `${resourcePathHref}?${path}` : path;
    }
  }

  return {
    props: {
      ...staticProps.props,
      signInProps: {
        destination,
        userEmail,
        sessionId: sessionId ?? null,
      },
    },
  };
}

SignInPage.getLayout = function getLayout(page: JSX.Element): JSX.Element {
  return <PublicLayout title="EXISTING_USER.header">{page}</PublicLayout>;
};
