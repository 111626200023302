import { Stack } from "@mui/material";
import { useRouter } from "next/router";
import { createContext, ReactNode, useEffect, useState } from "react";

import Alert, { IAlert } from "src/components/Alert";

export interface IAlertContext {
  addAlert: (newAlert: IAlert) => void;
}

export const AlertContext = createContext<IAlertContext>({
  addAlert: () => {},
});

export interface IAlertProviderProps {
  children: ReactNode;
}

export const MAX_ALERTS = 3;

interface IAlertWithKey extends IAlert {
  key: string | number;
}

export function AlertProvider({ children }: IAlertProviderProps): JSX.Element {
  const [alerts, setAlerts] = useState<IAlertWithKey[]>([]);

  const addAlert = (newAlert: IAlert) => {
    const timestamp = new Date().getTime();
    setAlerts((prevState) => [
      ...prevState,
      { ...newAlert, key: timestamp + prevState.length },
    ]);
  };

  const closeAlert = (index: number, callback?: () => void) => {
    setAlerts((prevState) => prevState.filter((alert, i) => i !== index));

    if (callback) {
      callback();
    }
  };

  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeStart", () => {
      setAlerts(() => []);
    });
  }, []);

  return (
    <AlertContext.Provider value={{ addAlert }}>
      {alerts.length > 0 && (
        <Stack
          spacing={1}
          sx={(theme) => ({
            position: "sticky",
            top: "4rem",
            pt: "1rem",
            zIndex: theme.zIndex.appBar - 1,
            backgroundColor: "background.secondary",
          })}
        >
          {alerts.slice(0, MAX_ALERTS).map((alert, index) => (
            <Alert
              {...alert}
              key={alert.key}
              onClose={() => closeAlert(index, alert.onClose)}
            />
          ))}
        </Stack>
      )}

      {children}
    </AlertContext.Provider>
  );
}
