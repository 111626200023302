import {
  PublicClientApplication,
  BrowserAuthError,
  BrowserAuthErrorCodes,
} from "@azure/msal-browser";
import { Button, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { useState } from "react";
import { useTranslations } from "next-intl";

import { useSafePush } from "src/hooks/auth/useSafePush";
import { useAsyncError } from "src/hooks/useAsyncError";
import { useStaffLogin } from "src/hooks/auth/useStaffLogin";

export function StaffLoginButton(): JSX.Element {
  const t = useTranslations("EXISTING_USER.staffLogin");
  const router = useRouter();
  const safePush = useSafePush();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setError = useAsyncError();
  const { mutateAsync: loginTillStaffMutation } = useStaffLogin();

  async function handleStaffSignin() {
    setIsLoading(true);

    const instance = new PublicClientApplication({
      auth: {
        clientId: process.env.REACT_APP_MS_AZURE_CLIENT_ID as string,
        authority: process.env.REACT_APP_MS_AZURE_URI as string,
        redirectUri: `${window.location.origin}/signin`,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
      },
    });

    await instance.initialize();

    const scopes = ["User.Read"];

    try {
      const { idToken } = await instance.loginPopup({ scopes });

      await loginTillStaffMutation({ idToken });
      await safePush(`${router.query.href ?? "/locations"}`, `/locations`);
    } catch (error) {
      setIsLoading(false);

      if (error instanceof BrowserAuthError) {
        switch (error.errorCode) {
          case BrowserAuthErrorCodes.userCancelled: {
            return;
          }
          case BrowserAuthErrorCodes.interactionInProgress: {
            setErrorMessage(t("error.interactionInProgress"));
            return;
          }
          default: {
            setErrorMessage(error.errorCode);
            return;
          }
        }
      }
      setError(error);
    }
  }

  return (
    <>
      <Button
        disabled={isLoading}
        sx={{ display: "flex", alignSelf: "center", width: "fit-content" }}
        variant="text"
        onClick={handleStaffSignin}
      >
        {t("buttonLabel")}
      </Button>
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
    </>
  );
}
